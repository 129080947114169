import styled from 'styled-components';

import { Breakpoints } from '@src/lib/styles/breakpoints';
import { FontSizes } from '@src/lib/styles/theme';

export const GridContainer = styled.div<{ $columnNumber: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columnNumber }) => $columnNumber}, 1fr);
  gap: 8px;
  margin-bottom: ${({ theme }) => theme.space.get('xl')};
  padding-bottom: 10px;
`;

export const Heading = styled.div<{ $size?: FontSizes }>`
  grid-column: 1 / -1;
  padding: 0;
  margin: 0;
  font-size: ${({ theme, $size }) => theme.fontSize.get($size || 'l')};
  font-weight: 800;
  text-transform: capitalize;
`;

export const StyledChip = styled.div<{
  $isSelected?: boolean;
  $fullHeight?: boolean;
  $isLocation?: boolean;
  $size?: FontSizes;
  $isDisable?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  padding: ${({ theme }) => theme.space.get('xs')};
  border: 2px solid #05b6d8;
  color: #05b6d8;
  font-size: ${({ theme, $size }) => theme.fontSize.get($size || 's')};
  border-radius: 5px;
  box-sizing: border-box;
  white-space: wrap;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  ${({ $fullHeight }) => ($fullHeight ? 'height: auto;' : '')}
  ${({ $isLocation }) => ($isLocation ? 'height: 50px;' : '')}
  ${({ $isSelected }) =>
    $isSelected
      ? `
  background: #05b6d8;
  color: white;
  `
      : ''}
  ${Breakpoints.MOBILE} {
    padding: ${({ theme }) => theme.space.get('2xs')};
  }

  ${({ $isDisable }) => ($isDisable ? 'background: darkgray; color: white; border: 2px solid darkgray;' : '')}
`;

export const DownloadButton = styled.button<{ $isSelected?: boolean }>`
  background-color: ${({ $isSelected }) => ($isSelected ? 'white' : '#05b6d8')};
  color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  border: none;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ $isSelected }) => ($isSelected ? '#e0f7fa' : '#028c9e')};
    color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  }

  &:active {
    background-color: ${({ $isSelected }) => ($isSelected ? '#b2ebf2' : '#016a74')};
    color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  }
`;

export const StyledHeading = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.get('2xs')};
  margin-bottom: ${({ theme }) => theme.space.get('2xs')};
  margin-top: ${({ theme }) => theme.space.get('2xs')};
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space.get('s')};
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
`;

export const StyledSelectedGrid = styled.div<{ $isConference?: boolean; $isNonMargin?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  ${({ $isConference }) =>
    $isConference
      ? ''
      : `
    
    grid-template-rows: repeat(2, 1fr);
    `}
  ${({ $isNonMargin, theme }) =>
    $isNonMargin
      ? ''
      : `
      
        margin-bottom: ${theme.space.get('xl')};
      `}
`;
