import { permissionEnum } from './types';

export const checkPermissions = (permission: permissionEnum): boolean => {
  console.log('🚀 ~ checkPermissions ~ permission:', permission);
  return true;
};

export const convertTo24HourFormat = (time12h: string): string => {
  const [time, modifier] = time12h.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};

export function convertTo12HourFormat(time24: string): string {
  const [hours, minutes] = time24.split(':').map(Number);

  if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return 'Invalid time format';
  }

  const period = hours < 12 ? 'AM' : 'PM';

  let hours12 = hours % 12;
  hours12 = hours12 === 0 ? 12 : hours12;

  const time12 = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return time12;
}

export function createTimeSlots(inputTime: string): { name: string; id: string }[] {
  const [timeData, modifier] = inputTime.split(/(?=[AP]M)/i);
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timeData.split(':').map(Number);

  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes, 0);

  const timeSlots = [];

  for (let i = -1; i <= 1; i++) {
    const newTime = new Date(date.getTime() + i * 30 * 60000);
    const name = formatTimeName(newTime);
    const id = formatTimeId(newTime);
    timeSlots.push({ name, id });
  }

  return timeSlots;
}

function formatTimeName(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert 0 to 12 for 12 AM/PM
  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
  return `${hours}:${minutesStr} ${ampm}`;
}

function formatTimeId(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0'); // Ensure two-digit hour
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes
  return `${hours}:${minutes}`;
}

export function isStartTimeSmaller(startTime: string, endTime: string): boolean {
  const startTime24 = convertTo24HourFormat(startTime);
  const endTime24 = convertTo24HourFormat(endTime);

  return startTime24 < endTime24;
}

export const getPosition = (key: string) => {
  switch (key) {
    case 'type':
      return { gridRow: '1/1', gridColumn: '3/3' };
    case 'floorNumber':
      return { gridRow: '2/2', gridColumn: '2/2' };
    case 'capacity':
      return { gridRow: '2/2', gridColumn: '3/3' };
    case 'mediaSupport':
      return { gridRow: '2/2', gridColumn: '1/1' };
    case 'location':
      return { gridRow: '1/1', gridColumn: '2/2' };
    case 'selectedDate':
      return { gridRow: '1/1', gridColumn: '1/1' };
  }
};
