import { Navigate } from 'react-router-dom';

import { UiRoutes } from '@src/lib/constants';
import { RouteComponentType } from '@src/lib/types';

// This is a placeholder function for your actual authentication check
const isAuthenticated = () => {
  return true;
};

const PrivateRoute = ({ component: Component }: RouteComponentType) => {
  // If the user is authenticated, render the component
  // Otherwise, redirect to the login page
  return isAuthenticated() ? <Component /> : <Navigate to={UiRoutes.LOGIN} replace />;
};

export default PrivateRoute;
