import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { MultipleBooking } from '@src/components/MultipleBooking';
import { UserSelect } from '@src/components/UserSelect';
import { dateOptions } from '@src/lib/constants';

import { callMsGraph } from '../../api/graph';
import { loginRequest } from '../../config/authConfig';

import { RenderChipComponent } from './RenderChipComponent';
import { GridContainer, StyledChip } from './style';

export const Calendar = ({
  handleSelect,
  selectedDate,
}: {
  handleSelect: (key: string, value: string) => void;
  selectedDate: string;
}) => {
  const [selectedDatePref, setSelectedDatePref] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedNewDate, setSelectedDate] = useState<any>();
  const [multiPersonBooking, setMultiPersonBooking] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const handleCheckboxChange = () => {
    setMultiPersonBooking(!multiPersonBooking);

    if (!multiPersonBooking && !isAuthenticated) handleLogin();
  };

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (selectedNewDate && selectedDatePref) {
      handleSelect('selectedDate', selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatePref, selectedDate]);

  useEffect(() => {
    const RequestContactData = async () => {
      try {
        setLoading(true);

        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const contacts = await callMsGraph(response.accessToken);
        setGraphData(contacts.value);
      } catch (error) {
        console.error('Failed to fetch contacts', error);
        setGraphData(null); // Reset graphData on failure
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && multiPersonBooking) {
      RequestContactData();
    }
  }, [isAuthenticated, multiPersonBooking, instance, accounts]);

  return (
    <div>
      <div style={{ marginBottom: '30px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={['day']}
            value={selectedNewDate ? dayjs(selectedNewDate) : selectedDate ? dayjs(selectedDate) : null}
            sx={{
              '&.MuiDateCalendar-root': {
                margin: '0',
                width: '100%',
                '.MuiDayCalendar-root': {
                  '.MuiDayCalendar-weekDayLabel': {
                    flex: 1,
                    fontWeight: '700',
                    fontSize: '15px',
                  },
                  '.MuiPickersDay-root': {
                    flex: 1,
                    fontSize: '15px',
                    borderRadius: '0px',
                    height: '40px',
                    fontWeight: '700',
                  },
                },
                '.MuiPickersCalendarHeader-root': {
                  '.MuiPickersCalendarHeader-label': {
                    fontWeight: '700',
                    fontSize: '15px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                  padding: '20px 12px 20px 24px',
                  background: 'purple',
                  color: 'white',
                  '.MuiSvgIcon-root': {
                    color: 'white',
                  },
                },
                '.MuiPickersCalendarHeader-switchViewButton': {
                  display: 'none',
                },
                '.Mui-selected': {
                  background: 'green',
                },
              },
            }}
            onChange={newValue => {
              setSelectedDate(newValue);

              if (selectedDatePref) {
                handleSelect('selectedDate', newValue);
              }
            }}
          />
        </LocalizationProvider>
      </div>

      <GridContainer $columnNumber={2}>
        {dateOptions?.map(({ id, name, isDisable, secondName }) => (
          <StyledChip
            key={id}
            onClick={() => {
              if (!isDisable) {
                setSelectedDatePref(id);

                if (selectedNewDate) {
                  handleSelect('selectedDate', selectedNewDate);
                }
              }
            }}
            $isDisable={isDisable}
            $isSelected={selectedDatePref === id}
            $isLocation
            style={{ flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {name}
            {secondName ? <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{` ${secondName}`}</span> : null}
          </StyledChip>
        ))}
        <div></div>
        <StyledChip
          $isDisable
          $isSelected
          $isLocation
          onClick={() => {
            // handleCheckboxChange();
          }}
          style={{ flexDirection: 'row', flexWrap: 'wrap' }}
        >
          multiperson booking
        </StyledChip>
      </GridContainer>
      {/* <RenderChipComponent
        columnNumber={2}
        handleSelect={(key, value) => {
          setSelectedDatePref(value);

          if (selectedNewDate) {
            handleSelect('selectedDate', selectedNewDate);
          }
        }}
        heading=''
        keyName='datePref'
        isSelctedValue={selectedDatePref}
        values={dateOptions}
      /> */}
      {/* <MultipleBooking multiPersonBooking={multiPersonBooking} onCheckboxChange={handleCheckboxChange} />
      {isAuthenticated ? (
        multiPersonBooking ? (
          loading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>
          ) : (
            graphData && <UserSelect usersList={graphData} />
          )
        ) : (
          ''
        )
      ) : (
        ''
      )} */}
    </div>
  );
};
