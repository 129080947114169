import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import Loader from './components/Loader';
import { msalConfig } from './config/authConfig';
import { GlobalUIContext } from './contexts/GlobalUIContext';
import { muiTheme } from './lib/styles/muiTheme';
import getTheme, { Modes, ModeType } from './lib/styles/theme';
import { Languages, LanguageType } from './lib/types';
import App from './App';

const AppWrapper = () => {
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState<Modes>(Modes.LIGHT);
  const theme = getTheme(mode);

  const msalInstance = new PublicClientApplication(msalConfig);

  useEffect(() => {
    const initializeApplication = async () => {
      try {
        const storedMode = getStoredMode();
        setMode(storedMode);

        const storedLanguage = getStoredLanguage();
        await i18n.changeLanguage(storedLanguage);
      } catch (error) {
        console.error('Failed to initialize app settings:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeApplication();
  }, [i18n]);

  if (loading) return <Loader />;

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <GlobalUIContext.Provider value={{ mode, setMode }}>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </GlobalUIContext.Provider>
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppWrapper;

const getStoredMode = (): ModeType => {
  const modeFromStorage = localStorage.getItem('mode') as ModeType;
  return modeFromStorage === Modes.DARK ? Modes.DARK : Modes.LIGHT;
};

const getStoredLanguage = (): LanguageType => {
  const langFromStorage = localStorage.getItem('lang') as LanguageType;
  return [Languages.EN, Languages.ES].includes(langFromStorage) ? langFromStorage : Languages.EN;
};
