import { MouseEvent, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';

const CarouselContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const ImageContainer = styled('div')({
  width: '100%',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const CarouselButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
});

export const ImageCarouselDialog = ({
  open,
  onClose,
  images,
}: {
  open: boolean;
  onClose: () => void;
  images: {
    filename: string;
    url: string;
  }[];
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCurrentImageIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
  };

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogTitle>
        Image Carousel
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CarouselContainer>
          <CarouselButton onClick={handlePrev} style={{ left: 10 }}>
            Prev
          </CarouselButton>
          <ImageContainer>
            <img
              src={images[currentImageIndex].url}
              alt={images[currentImageIndex].filename}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </ImageContainer>
          <CarouselButton onClick={handleNext} style={{ right: 10 }}>
            Next
          </CarouselButton>
        </CarouselContainer>
      </DialogContent>
    </Dialog>
  );
};
