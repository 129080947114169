import axios, { AxiosInstance, AxiosResponse } from 'axios';

// You can set your base URL here
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Axios instance configuration
const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  // You can add your headers here
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  config => {
    // You can add authentication tokens here
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can add any additional processing for the response here
    return response;
  },
  error => {
    // Handle response errors
    // For example, you could log out the user if a 401 is received
    if (error.response?.status === 401) {
      // Logout user or redirect to login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
