import { ComponentType } from 'react';

export type permissionEnum = 'VIEW_DATA_ONLY' | 'UPDATE_GATEWAY_CONFIG' | 'CREATE_REFUND';

export interface RoutesConfigType extends RouteComponentType {
  path: string;
  allowedPermission?: Array<permissionEnum>;
  layout: string;
}

export interface RouteComponentType {
  component: ComponentType;
}

export type LanguageType = Languages.EN | Languages.ES;
export enum Languages {
  EN = 'en',
  ES = 'es',
}
