export type Monochrome = 'black' | 'white';
export type PrimaryPalette = 'iconColors' | 'textColors' | 'bgColors' | 'linearColors';

export interface RawColorPalette {
  iconColors: {
    color01: string;
    color02: string;
    color03: string;
    color04: string;
    color05: string;
    color06: string;
    color07: string;
    color08: string;
  };
  bgColors: {
    color01: string;
    color02: string;
    color03: string;
  };
  linearColors: {
    color01: string;
    color02: string;
  };
  textColors: {
    color01: string;
    color02: string;
    color03: string;
  };
  black: string;
  white: string;
}

export const lightModeColors: RawColorPalette = {
  iconColors: {
    color01: '#5157AB',
    color02: '#EE8629',
    color03: '#66CF35',
    color04: '#D33B2B',
    color05: '#303B92',
    color06: '#F24423',
    color07: '#55AE22',
    color08: '#12133A',
  },
  bgColors: {
    color01: '#F4F6F6',
    color02: '#ECECEC',
    color03: '#8A8A8A',
  },
  linearColors: {
    color01: 'linear-gradient(#F8A960, #F08D30)',
    color02: 'linear-gradient(#585DAF, #333B92)',
  },
  textColors: {
    color01: '#333333',
    color02: '#EE8629',
    color03: '#5157AB',
  },
  black: '#1A1A1A',
  white: '#FFFFFF',
};

export const darkModeColors: RawColorPalette = {
  iconColors: {
    color01: '#5157AB',
    color02: '#EE8629',
    color03: '#66CF35',
    color04: '#D33B2B',
    color05: '#303B92',
    color06: '#F24423',
    color07: '#55AE22',
    color08: '#12133A',
  },
  bgColors: {
    color01: '#F4F6F6',
    color02: '#ECECEC',
    color03: '#8A8A8A',
  },
  linearColors: {
    color01: 'linear-gradient(#F8A960, #F08D30)',
    color02: 'linear-gradient(#585DAF, #333B92)',
  },
  textColors: {
    color01: '#333333',
    color02: '#EE8629',
    color03: '#5157AB',
  },
  black: '#1A1A1A',
  white: '#FFFFFF',
};

export type AllColorPalette = RawColorPalette;
