/* eslint-disable complexity */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Button, Checkbox } from '@mui/material';

import FormDialog from '@src/components/Modal/FormModal';
import { PageHeader } from '@src/components/PageHeader';
import { conferenceCities, mediaTypes, roomSizes, servicesOptions, time } from '@src/lib/constants';
import { createTimeSlots, getPosition } from '@src/lib/helper';

import { Calendar } from './Calender';
import { RenderChipComponent } from './RenderChipComponent';
import { RoomCard } from './roomCard';
import { GridContainer, Heading, StyledChip, StyledMainContainer, StyledSelectedGrid } from './style';
import { SelectedValues } from './type';
import { useHomeLogic } from './useHomeLogic';
dayjs.extend(utc);

const Home: React.FC = () => {
  const {
    containerRef,
    floorsOption,
    getValue,
    handleBookingSubmit,
    handleSelect,
    isDirtyServices,
    isOpen,
    locations,
    rooms,
    selectedServices,
    selectedToggle,
    selectedValues,
    setIsDirtyServices,
    setIsOpen,
    setSelectedServices,
    setSelectedValues,
    initialSelectedValue,
    setSelectedToggle,
    room,
    setRoom,
    locationSelectionType,
    setLocationSelectionType,
    getEmptyKey,
    errorMessage,
  } = useHomeLogic();

  const getPositionService = (id: string) => {
    switch (id) {
      case 'meals':
        return { gridColumn: '1' };
      case 'none':
        return { gridColumn: '2' };
      case 'beverages':
        return { gridColumn: '1' };
      case 'itSupport':
        return { gridColumn: '2' };
    }
  };

  const handleMultiLocationSelect = (isAllLocation = false) => {
    const data: SelectedValues = {
      ...selectedValues,
      floorNumber: '0',
      capacity: 'Any Size',
      mediaSupport: 'audio-video',
      type: 'conference',
    };

    if (isAllLocation) {
      data.location = locations?.map(({ id }) => id);
      setTimeout(() => {
        setSelectedToggle('services');
      }, 1000);
    } else {
      const toggle = getEmptyKey(data);
      setSelectedToggle(toggle || '');
    }

    setSelectedValues(data);
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  return (
    <>
      <PageHeader
        onClick={() => {
          setSelectedValues(initialSelectedValue);
          setSelectedToggle('selectedDate');
          setLocationSelectionType('');
        }}
      />
      <StyledMainContainer ref={containerRef}>
        {Object.values(selectedValues || {})
          ?.join()
          ?.replaceAll(',', '') ? (
          <div style={{ marginBottom: '10px' }}>
            <StyledSelectedGrid $isNonMargin={selectedValues?.type === 'conference' && !!selectedServices?.length}>
              {Object.keys(selectedValues).map(key => {
                const value = getValue(key, selectedValues[key] as string);
                return !['endTime', 'startTime', 'endTimeTemp', 'startTimeTemp'].includes(key) && value ? (
                  <StyledChip
                    key={key}
                    $isSelected
                    $isLocation
                    $isDisable={
                      !(
                        (locationSelectionType && !['floorNumber', 'type', 'capacity'].includes(key)) ||
                        !locationSelectionType
                      )
                    }
                    style={getPosition(key)}
                    onClick={() => {
                      if (
                        (locationSelectionType && !['floorNumber', 'type', 'capacity'].includes(key)) ||
                        !locationSelectionType
                      ) {
                        setSelectedToggle(key);

                        if (key === 'location') {
                          setSelectedValues(prev => ({ ...prev, floorNumber: '' }));
                        }

                        if (key === 'type') {
                          setSelectedValues(prev => ({ ...prev, capacity: '' }));
                        }

                        if (room) {
                          isOpen && setIsOpen(false);
                          setRoom(undefined);
                        }
                      }
                    }}
                  >
                    {key === 'mediaSupport' ? <div>Media</div> : null}
                    {key === 'floorNumber' ? (value === '0' ? 'Any Floor' : `${value} Floor`) : value}
                  </StyledChip>
                ) : null;
              })}
            </StyledSelectedGrid>
          </div>
        ) : null}
        {selectedValues?.type === 'conference' && selectedServices?.length ? (
          <div style={{ marginBottom: '10px' }}>
            <StyledSelectedGrid $isConference>
              {selectedServices?.map(service => {
                const label = servicesOptions?.find(({ id }) => id === service.name)?.name || '';
                return (
                  <StyledChip
                    $isSelected
                    key={label}
                    onClick={() => {
                      setSelectedToggle('services');
                      setIsDirtyServices(!!selectedServices?.length);
                      setRoom(undefined);
                    }}
                    style={{ background: '#047ea3', border: '1px solid #047ea3' }}
                  >
                    {label === 'None' ? ' NO SERVICES' : label}
                  </StyledChip>
                );
              })}
            </StyledSelectedGrid>
          </div>
        ) : null}
        {selectedToggle === 'selectedDate' ? (
          <Calendar handleSelect={handleSelect} selectedDate={selectedValues.selectedDate} />
        ) : null}
        {selectedToggle === 'location' && locations.length ? (
          <GridContainer $columnNumber={2}>
            <Heading
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
                textTransform: 'uppercase',
                ...(locationSelectionType === 'multiple'
                  ? {
                      justifyContent: 'space-between',
                    }
                  : {}),
              }}
            >
              location
              {locationSelectionType === 'multiple' ? (
                <Button
                  color='success'
                  variant='contained'
                  disabled={!(selectedValues?.location?.length > 1)}
                  onClick={() => {
                    handleMultiLocationSelect();
                  }}
                >
                  Done
                </Button>
              ) : null}
            </Heading>
            {locations.length
              ? locations?.map(({ id, name }) => (
                  <StyledChip
                    key={id}
                    $isSelected={selectedValues.location?.includes(id)}
                    $isDisable={locationSelectionType === 'allLocations'}
                    onClick={() => {
                      if (!locationSelectionType) {
                        handleSelect('location', id);
                        return;
                      }

                      locationSelectionType === 'multiple' &&
                        setSelectedValues(prev => {
                          const currentLocation = Array.isArray(prev.location) ? prev.location : [];
                          const locationSet = new Set(currentLocation);

                          if (locationSet.has(id)) {
                            locationSet.delete(id);
                          } else {
                            locationSet.add(id);
                          }

                          return { ...prev, location: Array.from(locationSet) };
                        });
                    }}
                  >
                    {name}
                  </StyledChip>
                ))
              : null}
            <div
              style={{
                border: '1px solid #05b6d8',
                borderRadius: '7px',
                display: 'flex',
                flexDirection: 'column',
                height: locationSelectionType === 'multiple' ? 'auto' : 'fit-content',
              }}
            >
              <StyledChip
                style={{
                  border: '1px solid #05b6d8',
                }}
                $isSelected={locationSelectionType === 'multiple'}
                onClick={() => {
                  setSelectedValues(prev => {
                    return { ...prev, location: [] };
                  });
                  setLocationSelectionType(prev => (prev !== 'multiple' ? 'multiple' : ''));
                }}
              >
                MULTIPLE LOCATIONS
              </StyledChip>
              {locationSelectionType === 'multiple' ? (
                <div
                  style={{
                    padding: '2px 5px',
                    fontSize: '12px',
                    marginTop: '4px',
                    fontWeight: 700,
                    color: 'red',
                  }}
                >
                  Please select two (2) or more locations and click 'Done'
                </div>
              ) : null}
            </div>
            <StyledChip
              onClick={() => {
                if (locationSelectionType === 'allLocations') {
                  setLocationSelectionType('');
                  setSelectedValues(prev => {
                    return { ...prev, location: [] };
                  });
                } else {
                  setLocationSelectionType('allLocations');
                  handleMultiLocationSelect(true);
                }
              }}
              $isSelected={locationSelectionType === 'allLocations'}
            >
              All Locations
            </StyledChip>
          </GridContainer>
        ) : null}
        {selectedValues.location && selectedToggle === 'floorNumber' ? (
          <RenderChipComponent
            {...{
              floors: [{ floorNumber: 0 }, ...(floorsOption?.floorDetails || [])],
              keyName: 'floorNumber',
              heading: 'Floor Number',
              columnNumber: 2,
              handleSelect,
              selectedValues,
              floorNotFound: floorsOption?.floorNotFound,
            }}
          />
        ) : null}
        {(selectedValues.location || floorsOption?.floorNotFound) && selectedToggle === 'type' ? (
          <RenderChipComponent
            {...{
              values: conferenceCities,
              keyName: 'type',
              heading: 'Room Type',
              columnNumber: 2,
              handleSelect,
              selectedValues,
            }}
          />
        ) : null}
        {selectedValues.type && selectedToggle === 'capacity' ? (
          <RenderChipComponent
            {...{ handleSelect, selectedValues, values: roomSizes, keyName: 'capacity', heading: 'Room Size' }}
          />
        ) : null}
        {selectedValues?.type === 'conference' && selectedToggle === 'services' ? (
          <div
            style={{
              marginBottom: '8px',
            }}
          >
            <Heading
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                userSelect: 'none',
              }}
            >
              Services
              <Button
                onClick={() => {
                  const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                    return !selectedValues[emptyValueKey];
                  });

                  setSelectedToggle(valueKey || '');
                  !selectedServices?.length && setSelectedServices([{ name: 'none' }]);
                }}
                variant='contained'
                sx={{ padding: 0, mb: 1 }}
                color='success'
              >
                Done
              </Button>
            </Heading>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '8px',
              }}
            >
              {servicesOptions?.map(({ id, name, subCheckBox }, index) => {
                const isSelected = selectedServices?.some(({ name: stateName }) => stateName === id);

                const removeNone = isDirtyServices
                  ? isDirtyServices
                  : id === 'none' &&
                    !selectedServices?.some(({ name: stateName }) => stateName === 'none') &&
                    selectedServices?.length
                  ? false
                  : true;

                const handleChipClick = () => {
                  isDirtyServices && setIsDirtyServices(false);

                  if (isSelected && selectedServices) {
                    setSelectedServices(selectedServices.filter(({ name: stateName }) => stateName !== id));
                  } else {
                    setSelectedServices(prev =>
                      id === 'none'
                        ? [{ name: 'none' }]
                        : [
                            ...(prev || []).filter(({ name: stateName }) => stateName !== 'none'),
                            {
                              name: id,
                              ...(subCheckBox
                                ? {
                                    categories:
                                      id === 'itSupport' ? ['ONLY audio-voice setup for conference call'] : ['Water'],
                                  }
                                : {}),
                            },
                          ]
                    );

                    if (id === 'none') {
                      const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                        return !selectedValues[emptyValueKey];
                      });

                      setSelectedToggle(valueKey || '');
                    }
                  }
                };

                return (
                  <div
                    style={{
                      ...getPositionService(id),
                      border: '1px solid #05b6d8',
                      borderRadius: '7px',
                      display: 'flex',
                      flexDirection: 'column',
                      height: isSelected ? 'auto' : 'fit-content',
                    }}
                  >
                    {/* rinku */}
                    <StyledChip
                      key={id}
                      style={{ border: '1px solid #05b6d8' }}
                      $isDisable={!removeNone}
                      $isSelected={
                        isSelected
                          ? subCheckBox
                            ? !!selectedServices?.find(({ name: stateName }) => stateName === id)?.categories?.length
                            : isSelected
                          : isSelected
                      }
                      onClick={() => {
                        removeNone && handleChipClick();
                      }}
                    >
                      {name}
                    </StyledChip>
                    {isSelected && subCheckBox ? (
                      <div
                        style={{
                          padding: '5px 5px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          flex: 1,
                          gap: '5px',
                        }}
                      >
                        {subCheckBox.map(categories => {
                          const selectedCategort =
                            selectedServices?.find(({ name: stateName }) => stateName === id)?.categories || [];

                          return (
                            <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10 px' }}>
                              <Checkbox
                                sx={{ padding: 0, mr: '5px' }}
                                checked={selectedCategort.includes(categories)}
                                onChange={({ target: { checked } }) => {
                                  if (checked) {
                                    setSelectedServices(prev =>
                                      prev?.map(value =>
                                        value?.name === id
                                          ? {
                                              ...value,
                                              categories:
                                                id === 'itSupport'
                                                  ? [categories]
                                                  : [...(value?.categories || []), categories],
                                            }
                                          : value
                                      )
                                    );
                                  } else {
                                    setSelectedServices(prev =>
                                      prev?.map(value =>
                                        value?.name === id
                                          ? {
                                              ...value,
                                              categories: (value?.categories || []).filter(
                                                valueName => valueName !== categories
                                              ),
                                            }
                                          : value
                                      )
                                    );
                                  }
                                }}
                              />
                              <div
                                style={{
                                  textTransform: id === 'beverages' ? 'uppercase' : 'none',
                                  fontSize: '12px',
                                  marginTop: '4px',
                                }}
                              >
                                {categories}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {selectedValues.capacity && selectedToggle === 'mediaSupport' ? (
          <RenderChipComponent
            {...{
              handleSelect,
              columnNumber: 2,
              selectedValues,
              values: mediaTypes,
              keyName: 'mediaSupport',
              heading: 'Media',
            }}
          />
        ) : null}
        {selectedValues.mediaSupport && selectedToggle === 'startTimeTemp' ? (
          <RenderChipComponent
            {...{ handleSelect, selectedValues, values: time, keyName: 'startTimeTemp', heading: 'Start Time' }}
          />
        ) : null}
        {selectedValues.startTimeTemp && selectedToggle === 'startTime' ? (
          <RenderChipComponent
            {...{
              handleSelect,
              selectedValues,
              values: createTimeSlots(selectedValues.startTimeTemp),
              keyName: 'startTime',
              heading: 'Select Start Time',
              headingSize: 'm',
            }}
          />
        ) : null}
        {selectedValues.startTime && selectedToggle === 'endTimeTemp' ? (
          <RenderChipComponent
            {...{ handleSelect, selectedValues, values: time, keyName: 'endTimeTemp', heading: 'End Time' }}
          />
        ) : null}
        {selectedValues.endTimeTemp && selectedToggle === 'endTime' ? (
          <RenderChipComponent
            {...{
              handleSelect,
              selectedValues,
              values: createTimeSlots(selectedValues.endTimeTemp),
              keyName: 'endTime',
              heading: 'Select End Time',
              headingSize: 'm',
            }}
          />
        ) : null}
        {selectedValues.endTime && selectedValues.startTime ? (
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(2, 1fr)`, gap: '10px', marginBottom: '10px' }}>
            {selectedValues.startTime ? (
              <StyledChip
                $isSelected
                style={{ background: 'rgb(5, 62, 86)', height: '50px' }}
                onClick={() => {
                  setSelectedToggle('startTimeTemp');
                }}
              >
                <div>Start Time</div>
                {getValue('startTime', selectedValues.startTime)}
              </StyledChip>
            ) : null}
            {selectedValues.endTime ? (
              <StyledChip
                $isSelected
                style={{ background: 'rgb(5, 62, 86)', height: '50px' }}
                onClick={() => {
                  setSelectedToggle('endTimeTemp');
                }}
              >
                <div>End Time</div>
                {getValue('endTime', selectedValues.endTime)}
              </StyledChip>
            ) : null}
          </div>
        ) : null}
        {rooms?.length && !locationSelectionType ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(2, 1fr)`,
              gap: '10px',
              paddingBottom: '10px',
            }}
          >
            {rooms.map(roomDetaild => {
              const isRoomSelcted = room ? room?.roomId === roomDetaild._id : true;

              return isRoomSelcted ? (
                <RoomCard
                  key={roomDetaild._id}
                  room={roomDetaild}
                  selectedValues={selectedValues}
                  onClick={() => {
                    setRoom({
                      roomNumber: roomDetaild?.roomNumber,
                      endTime: selectedValues.endTime,
                      startTime: selectedValues.startTime,
                      roomId: roomDetaild._id,
                    });
                    setSelectedToggle('selectRoom');
                  }}
                />
              ) : null;
            })}
          </div>
        ) : null}
        {selectedToggle === 'selectRoom' ? (
          <FormDialog
            isOpen={!!selectedToggle}
            scrollToBottom={scrollToBottom}
            handleClose={() => {
              const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                return !selectedValues[emptyValueKey];
              });

              setSelectedToggle(valueKey || '');
            }}
            onSubmit={handleBookingSubmit}
          />
        ) : null}
        {errorMessage && !selectedToggle ? <h3 style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</h3> : null}
      </StyledMainContainer>
    </>
  );
};

export default Home;
