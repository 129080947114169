import { useNavigate } from 'react-router-dom';

import { Button, Divider } from '@mui/material';

import { UiRoutes } from '@src/lib/constants';
import { Header } from '@src/screens/Booking/style';

export const PageHeader = ({ onClick }: { onClick?: () => void }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(UiRoutes.HOME);
  };

  const handleMyBooking = () => {
    navigate(UiRoutes.MY_BOOKING);
  };

  return (
    <div style={{ position: 'sticky', top: 0, background: 'white', zIndex: '100' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <Header style={{ background: 'transparent' }}>
          <img src='/images/1000276066.png' alt='' onClick={onClick ? onClick : handleNavigation} />
        </Header>
        <Button
          onClick={handleMyBooking}
          variant='outlined'
          style={{
            fontWeight: '700',
            color: '#333',
            padding: '6px 8px',
            textTransform: 'none',
            borderRadius: '10px',
            backgroundColor: 'rgb( 222,255,251)',
            fontSize: '14px',
          }}
        >
          My Bookings
        </Button>
      </div>
      <Divider />
    </div>
  );
};
