import styled from 'styled-components';

import { StyledChip } from '../Home/style';

export const Header = styled.div`
  display: flex;
  align-items: center;
  background: #294753;
  box-sizing: border-box;

  h3 {
    font-size: 18px;
    color: white;
    font-weight: 400;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px;
  background: #f0f4f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const DetailItem = styled.div`
  text-transform: capitalize;
  font-size: 25px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StatusChip = styled(StyledChip)`
  background: green;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
`;

export const ActionChip = styled(StyledChip)<{ status?: string }>`
  background: #0392a2;
  color: #fff;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  font-size: 35px;
  font-weight: 300;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  ${({ status }) => {
    switch (status) {
      case 'reschedule':
        return {
          background: '#ffc000',
          justifyContent: 'center',
          fontSize: '26px',
          fontWeight: '700',
          color: 'black',
          border: '1px solid #82939b',
        };
      case 'cancel':
        return {
          background: '#ff0000',
          justifyContent: 'center',
          fontSize: '26px',
          fontWeight: '700',
          color: 'white',
          border: '1px solid #a8a9c0',
        };
      case 'confirm':
        return {
          background: 'green',
          justifyContent: 'center',
          fontSize: '26px',
          fontWeight: '700',
          color: 'white',
        };
      default:
        return { background: '#0392a2' };
    }
  }};
`;
