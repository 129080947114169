import { createContext } from 'react';

import { Modes, ModeType } from '@src/lib/styles/theme';

interface GlobalUIContextProps {
  mode: ModeType;
  setMode?: (val: Modes) => void;
}

export const GlobalUIContext = createContext<GlobalUIContextProps>({ mode: Modes.LIGHT });
