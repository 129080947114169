import ImageModal from './ImageModal';
import { GridContainer, Heading, StyledChip } from './style';
export const RenderChipComponent = ({
  heading,
  keyName,
  floors,
  values,
  columnNumber = 3,
  handleSelect,
  selectedValues,
  floorNotFound,
  isSelctedValue,
}: {
  values?: { id: string; name: string; isDisable?: boolean; secondName?: string; line2?: string }[];
  keyName: string;
  floors?: { floorNumber: number; floorMap?: string; floorMapThumbnail?: string }[];
  heading: string;
  handleSelect: (key: string, value: string) => void;
  columnNumber?: number;
  floorNotFound?: boolean;
  isSelctedValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedValues?: any;
}) => {
  return (
    <GridContainer $columnNumber={columnNumber}>
      <Heading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          userSelect: 'none',
          textTransform: 'uppercase',
        }}
      >
        {heading}
      </Heading>
      {values?.map(({ id, name, isDisable, secondName, line2 }) => (
        <StyledChip
          key={id}
          onClick={() => {
            handleSelect(keyName, id);
          }}
          $isDisable={isDisable}
          $isSelected={selectedValues?.[keyName] === id || id === isSelctedValue}
          $isLocation={['location', 'datePref', 'startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'].includes(
            keyName
          )}
          $size={
            keyName === 'capacity'
              ? 'xs'
              : ['startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'].includes(keyName)
              ? 'm'
              : undefined
          }
          style={line2 ? { flexDirection: 'column', flexWrap: 'wrap' } : { flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {name}
          {secondName ? <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{` ${secondName}`}</span> : null}
          {line2 ? <div>{line2}</div> : null}
        </StyledChip>
      ))}
      {floors?.map(({ floorNumber, floorMap, floorMapThumbnail }) => (
        <div key={`${floorNumber}-floors`}>
          <div>
            <ImageModal
              imageUrl={floorMap || ''}
              thumbnailUrl={floorNumber === 0 ? '/images/1000289544.png' : floorMapThumbnail}
              text={floorNumber === 0 ? 'Any Floor' : `${floorNumber} Floor`}
              handleTextClick={() => {
                handleSelect(keyName, floorNumber.toString());
              }}
              isSelected={selectedValues?.[keyName] === floorNumber.toString()}
            />
          </div>
        </div>
      ))}
      {floorNotFound && keyName === 'floorNumber' ? (
        <StyledChip style={{ gridColumn: '1/-1' }}>No Floor Found</StyledChip>
      ) : null}
    </GridContainer>
  );
};
