import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { createBooking, updateBooking } from '@src/api/booking';
import { findMaxSizeRoom, findRoom, getLocations } from '@src/api/location';
import { getFloorNumber } from '@src/api/rooms';
import { convertTo12HourFormat } from '@src/lib/helper';
import { RoomsType } from '@src/Modals/Rooms';

import { SelectedValues } from './type';

dayjs.extend(utc);

export const useHomeLogic = () => {
  const navigate = useNavigate();
  const reschedule = useSearchParams()[0].get('reschedule');

  const initialSelectedValue = {
    selectedDate: '',
    location: '',
    type: '',
    floorNumber: '',
    capacity: '',
    mediaSupport: '',
    startTimeTemp: '',
    startTime: '',
    endTimeTemp: '',
    endTime: '',
  };

  const [selectedValues, setSelectedValues] = useState<SelectedValues>(initialSelectedValue);

  const [locations, setLocations] = useState<
    { id: string; name: string; timeZone: { label: string; value: string } }[]
  >([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomsType[] | undefined>();
  const [room, setRoom] = useState<Record<string, string> | undefined>();
  const [selectedToggle, setSelectedToggle] = useState('selectedDate');
  const [selectedServices, setSelectedServices] = useState<{ name: string; categories?: string[] }[]>();
  const [isDirtyServices, setIsDirtyServices] = useState(false);
  const [locationSelectionType, setLocationSelectionType] = useState<'allLocations' | 'multiple' | ''>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [floorsOption, setFloorsOption] = useState<{
    floorDetails: { floorNumber: number; floorMap?: string }[];
    floorNotFound: boolean;
  }>();

  // eslint-disable-next-line complexity
  const handleBookingSubmit = async ({ userId, alternativeName }: { userId: string; alternativeName?: string }) => {
    try {
      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
      localStorage.setItem('serviceValue', JSON.stringify(selectedServices));
      const bookingDate = getBookingDate() || '';

      if (userId) {
        if (reschedule) {
          const bookingRes = await updateBooking(reschedule, {
            room: room?.roomId || '',
            bookingDate,
            endTime: selectedValues?.endTime || '',
            startTime: selectedValues?.startTime || '',
            user: userId,
            location: selectedValues?.location.toString() || '',
            status: 'Confirmed',
            ...(alternativeName ? { alternativeName } : {}),
          });

          navigate(`/booking/${bookingRes?.id}?roomId=${room?.roomId}`);
        } else {
          if (!locationSelectionType) {
            const bookingRes = await createBooking({
              room: room?.roomId || '',
              bookingDate,
              endTime: selectedValues?.endTime || '',
              startTime: selectedValues?.startTime || '',
              user: userId,
              services: selectedServices,
              location: selectedValues?.location.toString() || '',
              status: 'Confirmed',
              ...(alternativeName ? { alternativeName } : {}),
            });

            navigate(`/booking/${bookingRes?.id}`);
          } else {
            const bookingList = [];

            for (const roomData of rooms || []) {
              const bookingRes = await createBooking({
                room: roomData?._id || '',
                bookingDate,
                endTime: selectedValues?.endTime || '',
                startTime: selectedValues?.startTime || '',
                user: userId,
                services: selectedServices,
                location: roomData?.location,
                status: 'Confirmed',
                ...(alternativeName ? { alternativeName } : {}),
              });

              bookingList.push(bookingRes?.id);
            }

            navigate(`/booking/${bookingList.toString()}`);
          }
        }
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const getEmptyKey = (data: SelectedValues) => {
    const valueKey = Object.keys(data).find(emptyValueKey => {
      return !data[emptyValueKey];
    });

    return valueKey;
  };

  const handleSelect = useCallback(
    // eslint-disable-next-line complexity
    (key: string, value: string) => {
      const data: SelectedValues = { ...selectedValues, [key]: value };

      if (key === 'location') {
        data.location = value;
        data.floorNumber = '';
      }

      if (key === 'type' && value === 'hotel') {
        data.capacity = data.capacity || '1 person';
        data.floorNumber = data?.floorNumber || '0';
        data.mediaSupport = 'none';
      }

      if (key === 'capacity' && data?.type !== 'hotel') {
        data.floorNumber = data?.floorNumber || '0';
      }

      if (value === 'hotel') {
        setSelectedServices([]);
      }

      const valueKey = getEmptyKey(data);
      setSelectedValues(data);

      switch (key) {
        case 'startTimeTemp':
        case 'endTimeTemp':
          setSelectedToggle(key.slice(0, -4));
          break;

        case 'location':
          setSelectedToggle('type');
          break;

        case 'type':
          if (value === 'hotel') {
            setSelectedToggle(valueKey || '');
          } else {
            setSelectedToggle('capacity');
          }

          break;

        case 'capacity':
          if (selectedValues?.type !== 'hotel') {
            setIsDirtyServices(!!selectedServices?.length);
          }

          if (selectedValues?.mediaSupport && selectedValues?.type !== 'hotel' && !selectedServices?.length) {
            setSelectedToggle('services');
          } else {
            if (
              selectedValues?.type !== 'hotel' &&
              floorsOption?.floorDetails.length &&
              floorsOption.floorDetails?.length > 1
            ) {
              setSelectedToggle('floorNumber');
            } else {
              setSelectedToggle(valueKey || '');
            }
          }

          break;
        case 'mediaSupport':
          setSelectedToggle(selectedValues?.type === 'hotel' ? valueKey || '' : 'services');
          break;
        default:
          setSelectedToggle(valueKey || '');
          break;
      }

      if (room) {
        setIsOpen(false);
        setRoom(undefined);
      }
    },
    [floorsOption?.floorDetails?.length, room, selectedServices?.length, selectedValues]
  );

  const fetchData = useCallback(async () => {
    try {
      const options = { limit: 30 };
      const responseLocation = await getLocations(options);
      setLocations(responseLocation?.results || []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getValue = (key: string, value: string) => {
    switch (key) {
      case 'selectedDate':
        return dayjs(value).format('MMMM Do');
      case 'location':
        if (locationSelectionType) {
          return locationSelectionType === 'multiple' ? 'Multiple Location' : 'All Locations';
        }

        return locations?.find(({ id }) => id === value)?.name;
      case 'endTime':
        return value ? convertTo12HourFormat(value) : '';
      case 'startTime':
        return value ? convertTo12HourFormat(value) : '';
      default:
        return value || '';
    }
  };

  const fetchFloors = async () => {
    if (!selectedValues.location) return;

    const responseFloor = await getFloorNumber(selectedValues.location.toString());

    setFloorsOption(responseFloor || []);
  };

  const getBookingDate = () => {
    return dayjs(selectedValues?.selectedDate)?.format('YYYY-MM-DD');
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!selectedValues.location || locationSelectionType) return;

    fetchFloors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.location]);

  useEffect(() => {
    const { location, type, capacity, mediaSupport, startTime, endTime, floorNumber, selectedDate } = selectedValues;

    const handleFindRoom = async () => {
      if (locationSelectionType) {
        findMaxSizeRoom({
          location: location.toString(),
          type,
          capacity: '1 person',
          mediaSupport,
          startTime,
          endTime,
          floorNumber: +floorNumber,
          bookingDate: getBookingDate() || '',
        })
          .then(res => {
            setRooms(res?.rooms || []);
            setSelectedToggle('selectRoom');
            setErrorMessage('');
          })
          .catch(err => {
            console.log('>>>', err);

            setErrorMessage(err.response.data.error);
          });
        return;
      }

      findRoom({
        location: location.toString(),
        type,
        capacity,
        mediaSupport,
        startTime,
        endTime,
        floorNumber: +floorNumber,
        bookingDate: getBookingDate() || '',
      })
        .then(res => {
          setRooms(res);
          setErrorMessage('');
        })
        .catch(err => {
          setRooms([]);
        });
    };

    if (location && type && capacity && mediaSupport && startTime && endTime && floorNumber && selectedDate) {
      handleFindRoom();
    } else {
      setRooms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (reschedule) {
      const storedValues = localStorage.getItem('seletedValues');
      const parsedValues = storedValues ? JSON.parse(storedValues) : null;

      const serviceValue = localStorage.getItem('serviceValue');

      if (serviceValue) {
        setSelectedServices(JSON.parse(serviceValue));
      }

      if (parsedValues) {
        setSelectedValues(parsedValues as SelectedValues);

        const valueKey = Object.keys(parsedValues).find(emptyValueKey => {
          return !parsedValues[emptyValueKey];
        });

        setSelectedToggle(valueKey || '');
      }
    }
  }, [reschedule]);

  return {
    selectedValues,
    handleBookingSubmit,
    handleSelect,
    getValue,
    rooms,
    isOpen,
    setIsOpen,
    selectedToggle,
    selectedServices,
    setSelectedServices,
    isDirtyServices,
    setIsDirtyServices,
    containerRef,
    floorsOption,
    locations,
    setSelectedValues,
    initialSelectedValue,
    setSelectedToggle,
    room,
    setRoom,
    locationSelectionType,
    setLocationSelectionType,
    getEmptyKey,
    errorMessage,
  };
};
