import { useTranslation } from 'react-i18next';

import imgUrl from '@src/lib/imgUrl';

import Props from './types';

const Loader = ({ message }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className='overlay'></div>
      <div className='modal modal-dialog-centered loader' id='loader'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              <img src={imgUrl.loader} alt='loader' />
              {<h4>{(message && message) || `${t('loading')}...`}</h4>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
