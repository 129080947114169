/* eslint-disable @typescript-eslint/no-explicit-any */
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const getLocations = async (params: { limit: number }): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.LOCATION_LIST}`,
    config: { params },
  });
};

export const findRoom = (params: {
  location: string;
  type: string;
  capacity: string;
  mediaSupport: string;
  startTime: string;
  endTime: string;
  floorNumber: number;
  bookingDate: string;
}): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.FIND_ROOM}`,
    config: { params },
  });
};

export const findMaxSizeRoom = (params: {
  location: string;
  type: string;
  capacity: string;
  mediaSupport: string;
  startTime: string;
  endTime: string;
  floorNumber: number;
  bookingDate: string;
}): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MAX_SIZE_ROOM}`,
    config: { params },
  });
};
