import { Suspense, useCallback } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '@src/components/Loader';
import PrivateRoute from '@src/layouts/PrivateRoute';
import PublicRoute from '@src/layouts/PublicRoute';
import { checkPermissions } from '@src/lib/helper';
import { permissionEnum, RoutesConfigType } from '@src/lib/types';
import routesConfig from '@src/routes/routesConfig';
import Home from '@src/screens/Home';

const App = () => {
  const checkPermissionMethod = useCallback(
    (permissions: Array<permissionEnum>): boolean => {
      return permissions.some(permissionName => checkPermissions(permissionName));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(['Create_user'])] //get user permissions from Backend and store in store.
  );

  const renderLayout = (config: RoutesConfigType): JSX.Element => {
    const Component = config.component;

    switch (config.layout) {
      case 'private':
        return <Route key='private' path={config.path} element={<PrivateRoute component={Component} />} />;
      case 'public':
        return <Route key='public' path={config.path} element={<PublicRoute component={Component} />} />;
      default:
        return <Route key='default' path={config.path} element={<PrivateRoute component={Component} />} />;
    }
  };

  const renderRoute = (config: RoutesConfigType): JSX.Element | undefined => {
    if (config.allowedPermission) {
      if (checkPermissionMethod(config.allowedPermission)) {
        return renderLayout(config);
      }

      return undefined;
    } else {
      return renderLayout(config);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {routesConfig.map(config => renderRoute(config))}
          <Route key='home' path='/lewisroca' element={<Home />} />
          <Route path='*' element={<Navigate to='/lewisroca' replace />} />
          {/* remove this route  */}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
