import { graphConfig } from '../config/authConfig';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken The access token to attach to the API call
 * @returns A Promise that resolves to the user information
 */
export async function callMsGraph(accessToken: string): Promise<any> {
  const headers: Headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options: RequestInit = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
