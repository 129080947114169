/* eslint-disable @typescript-eslint/no-explicit-any */
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

// Create a new booking
export const createBooking = (bookingData: {
  user: string;
  room: string;
  startTime: string;
  endTime: string;
  bookingDate: string;
  location: string;
  status: string;
  services?: {
    name: string;
    type?: string[];
  }[];
}): Promise<any> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.BOOKINGS}`,
    data: bookingData,
  });
};

export const getBookings = (
  filters: {
    startDate?: string;
    endDate?: string;
  } = {}
): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.BOOKINGS}`,
    config: { params: filters },
  });
};

export const getBookingById = (bookingId: string): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.BOOKINGS}/${bookingId}`,
  });
};

export const getBookingsByIds = (bookingId: string): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.BOOKINGS_BY_IDS}/${bookingId}`,
  });
};

export const updateBooking = (
  bookingId: string,
  updateData: {
    user?: string;
    room?: string;
    startTime?: string;
    endTime?: string;
    bookingDate?: string;
    location?: string;
    status?: string;
  }
): Promise<any> => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.BOOKINGS}/${bookingId}`,
    data: updateData,
  });
};

export const deleteBooking = (bookingId: string): Promise<any> => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.BOOKINGS}/${bookingId}`,
  });
};

export const getBookingToken = ({ phoneNumber }: { phoneNumber: string }): Promise<any> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.GET_BOOKING_TOKEN}`,
    data: { phoneNumber },
  });
};

export const getUserBooking = (token: string): Promise<any> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.USER_BOOKING}`,
    config: { headers: { Authorization: `Bearer ${token}` } },
  });
};
