import { createTheme } from '@mui/material/styles';

// import { theme as styledTheme } from './theme';

const muiTheme = createTheme({
  typography: {
    fontFamily: ` 'GT-America Standard Regular', sans-serif`,
  },
  // palette: {
  //   primary: {
  //     main: `${styledTheme.colors.black.color01}`,
  //   },
  //   secondary: {
  //     main: `${styledTheme.colors.black.color02}`,
  //   },
  // },
});

export { muiTheme };
